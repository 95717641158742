import { Injectable } from '@angular/core';
import { Roles } from '../../core/const/roles';
import { forkJoin, Observable } from 'rxjs';
import { trainingStatus } from '../../core/const/trainingStatus';
import { TrainingService } from '../../core/service/training.service';
import { map } from 'rxjs/operators';
import { WorkbookService } from '../../core/service/workbook.service';
import { SurveyService } from '../../core/service/survey.service';

@Injectable()
export class SharedService {

  constructor(
    private trainingService: TrainingService,
    private workbookService: WorkbookService,
    private surveyService: SurveyService,
  ) {}

  dataFactory(role: string = Roles.USER, route?: string): Observable<any[]> {
    switch (role) {
      case Roles.CONTENT_MANAGER:
      case Roles.REVIEWER:
        return forkJoin([
          this.fetchOverviewData('workbook', 0, 6).pipe(map(data => {
            return { type: 'workbook', data: { total: data.total, items: data.items } };
          })),
          this.fetchOverviewData('survey', 0, 6).pipe(map(data => {
            return { type: 'survey', data: { total: data.total, items: data.items } };
          })),
        ]);

      default:
        return route === '/shared/overview/workbooks'
        // For workbooks review by trainers
        ? forkJoin([this.fetchOverviewData('workbook', 0, 6).pipe(map(data => {
          return { type: 'workbook', data: { total: data.total, items: data.items } };
        }))])
        : forkJoin([
          this.fetchOverviewData(trainingStatus.NEW, 0, 6).pipe(map(data => {
            return { type: trainingStatus.NEW, data };
          })),
          this.fetchOverviewData(trainingStatus.ACTIVE, 0, 6).pipe(map(data => {
            return { type: trainingStatus.ACTIVE, data };
          })),
          this.fetchOverviewData(trainingStatus.ENDED, 0, 6).pipe(map(data => {
            return { type: trainingStatus.ENDED, data };
          })),
        ]);
    }
  }

  fetchOverviewData(dataType: any, skip: number, take: number): Observable<any> {
    switch (dataType) {
      case trainingStatus.ACTIVE:
      case trainingStatus.ENDED:
      case trainingStatus.NEW:
        return this.trainingService.fetchTrainings(dataType, skip, take);
      case 'workbook':
        return this.workbookService.fetchWorkbookList(skip, take);
      case 'survey':
        return this.surveyService.getAllSurveys(skip, take);
    }
  }
}
